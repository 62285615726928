<script>
import { required } from "vuelidate/lib/validators";
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";

const initialState = () => {
    return {
        submitted: false,
        raffle_participants: [],
    };
}

export default {
    emits: ['onSuccess'],
    props: {
      raffle: {
            type: Object,
            default: () => {}
        },
        modalId: String,
        type: {
            type: String,
            default: 'winners'
        }
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        SelectAutocompleteField
    },
    computed: {
    },
    validations: {
        raffle_participants: { required }
    },
    methods: {
        async shown() {
        },
        async confirm() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            const data = [ ...this.raffle_participants ];
            const raffle_participants = data?.map(item => item?.id);

            const response = await api.save({
                url: `raffles/${this.raffle?.id}/participants`,
                data: {
                    raffle_participants: raffle_participants,
                    is_winner: this.type === 'winners',
                    is_substitute: this.type === 'substitutes'
                },
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess", response?.data?.data);
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
    }
};
</script>

<template>
    <Modal :title="'Formulario de sorteo'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal">
        <div class="row">
            <div class="form-group col-12" style="min-height: 300px;">
                <label>Titulares</label>
                <SelectAutocompleteField
                    :url="`/customers`"
                    :multiple="true"
                    :placeholder="'Busca un titular'"
                    :label="'name'"
                    :params="{ 'customer_type': 'holder' }"
                    v-model="raffle_participants"
                    :class="{ 'is-invalid': $v.raffle_participants.$error && submitted }"
                ></SelectAutocompleteField>
                <div v-if="!$v.raffle_participants.required && submitted" class="invalid-feedback">Campo requerido</div>
            </div>                                    
        </div>
    </Modal>
</template>