<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editor: ClassicEditor,
        }
    },
    components: {        
        ckeditor: CKEditor.component,
    },
    methods: {
        
    },
};
</script>

<template>
    <div class="row">
        <div class="form-group col-12">
            <label for="email_description">Mensaje</label>
            <p class="text-muted font-13">Utiliza este campo para ingresar el mensaje que deseas enviar a los ganadores y suplentes.</p>
            <ckeditor id="email_description" v-model="value.email_description" :editor="editor"></ckeditor>
            <div v-if="v.raffle.email_description.errors && submitted" class="invalid-feedback d-block">Ingresa un mensaje</div>
        </div>
    </div> 
</template>