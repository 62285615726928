<script>
import Status from "@/helpers/general/status";
export default {
    props: {
        value: Array,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            raffle_field_types: Status.getList('raffle_field_types'),
        }
    },
    methods: {
        addRow(){
            this.value.push({
                type: null,
                label: "",
            });
        },
        deleteRow(key){
            this.value.splice(key, 1);
        },
    },
};
</script>

<template>
    <div>
        <table class="table table-bordered table-sticky-header">
            <thead>
                <tr>
                    <th scope="col">Tipo</th>
                    <th scope="col">Nombre</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(field, i) in value" :key="i" :set="vattr = v.raffle.raffle_form_fields.$each[i]">
                    <td>
                        <div class="form-group">
                            <select v-model="field.type" class="form-control"
                                :class="{ 'is-invalid': vattr.type.$error && submitted }">
                                <option value="">-- Seleccione --</option>
                                <option v-for="option in raffle_field_types" v-bind:value="option.id" :key="option.id">
                                    {{ option.label }}
                                </option>
                            </select>                                           
                        </div>
                    </td>
                    <td>
                        <div class="form-group">
                            <input
                                id="label"
                                v-model="field.label"
                                type="text"
                                class="form-control"
                                placeholder="Ingresa el nombre del campo"
                                :class="{ 'is-invalid': vattr.label.$error && submitted }"
                            />
                        </div>
                    </td>                    
                    <td class="text-center">
                        <a
                            href="javascript:void(0);"
                            class="text-danger"
                            v-b-tooltip.hover
                            title="Eliminar"
                            @click="deleteRow(i)"
                        >
                            <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="4" class="text-right">
                        <a
                            href="javascript:void(0);"
                            class="text-success"
                            @click="addRow()"
                        >
                            <i class="mdi mdi-plus-circle font-size-18"></i> Agregar campo
                        </a>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>