<script>
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";
import AddIndividualMediaButton from "@/components/buttons/add-individual-media-button.vue";
import Status from "@/helpers/general/status";
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            raffle_status: Status.getList('raffle_status'),
            raffle_types: Status.getList('raffle_types'),
            raffle_visibility: Status.getList('raffle_visibility'),
        }
    },
    components: {        
        SelectAutocompleteField,
        AddIndividualMediaButton,
    },
    methods: {
        
    },
};
</script>

<template>
    <div class="row">
        <div class="col-12">
            <label>Imagen de cabecera</label>
            <AddIndividualMediaButton v-model="value.header_media" :fullWidth="true" :onlyImages="true" :uploadModalId="'add-individual-media-modal-header-media'"></AddIndividualMediaButton>
            <div v-if="!v.raffle.header_media.required && submitted" class="invalid-feedback d-block">Imagen de cabecera es requerida</div>
        </div>
        <div class="form-group col-12">
            <label for="type">Tipo</label>
            <select v-model="value.type" class="form-control"
                :class="{ 'is-invalid': v.raffle.type.$error && submitted }">
                <option v-for="option in raffle_types" v-bind:value="option.id" :key="option.id">
                    {{ option.label }}
                </option>
            </select>
        </div>
        <div class="form-group col-12">
            <label for="title">Titulo</label>
            <div class="input-group">
                <input id="title" v-model="value.title" type="text" class="form-control" placeholder="Ingresa un nombre"
                :class="{ 'is-invalid': v.raffle.title.$error && submitted }" />
                <div class="input-group-prepend" v-b-tooltip.hover title="Marque esta opción si desea ocultar el titulo en la vista de detalle del sorteo">
                    <div class="input-group-text">
                        <label for="detail_hide_title" class="mb-0">Ocultar en detalle</label>
                        <input
                            type="checkbox"
                            class="form-controls ml-2"
                            id="detail_hide_title"
                            v-model="value.detail_hide_title"
                        />
                    </div>                    
                </div>
            </div>
            <div v-if="!v.raffle.title.required && submitted" class="invalid-feedback">Titulo es requerido</div>
        </div>
        <div class="form-group col-12">
            <label for="slug">Slug</label>
            <p class="text-muted font-13">El «slug» es la versión amigable de la URL para el titulo. Suele estar todo en minúsculas y contiene solo letras, números y guiones.</p>
            <input id="slug" v-model="value.slug" type="text" class="form-control" placeholder="Ingresa un slug"
            :class="{ 'is-invalid': v.raffle.slug.$error && submitted }" />
            <div v-if="v.raffle.slug.$error && submitted" class="invalid-feedback">Slug inválido</div>
        </div>
        <div class="form-group col-12">
            <label for="prize_value" title="Valor del premio individual">Valor del premio</label>
            <input
                id="prize_value"
                v-model="value.prize_value"
                type="number"
                class="form-control"
                placeholder="Ingresa un número"
                :class="{ 'is-invalid': v.raffle.prize_value.$error && submitted }"
            />
            <div v-if="v.raffle.prize_value.$error && submitted" class="invalid-feedback">Valor inválido</div>
        </div> 
        <div class="form-group col-lg-6 col-md-12">
            <label for="number_of_winners">Cantidad de ganadores</label>
            <input
                id="number_of_winners"
                v-model="value.number_of_winners"
                type="number"
                class="form-control"
                placeholder="Ingresa un número"
                :class="{ 'is-invalid': v.raffle.number_of_winners.$error && submitted }"
            />
            <div v-if="v.raffle.number_of_winners.$error && submitted" class="invalid-feedback">Valor inválido</div>
        </div>        
        <div class="form-group col-lg-6 col-md-12">
            <label for="number_of_substitutes">Cantidad de suplentes</label>
            <input
                id="number_of_substitutes"
                v-model="value.number_of_substitutes"
                type="number"
                class="form-control"
                placeholder="Ingresa un número"
                :class="{ 'is-invalid': v.raffle.number_of_substitutes.$error && submitted }"
            />
            <div v-if="v.raffle.number_of_substitutes.$error && submitted" class="invalid-feedback">Valor inválido</div>
        </div>          
        <div class="form-group col-lg-6 col-md-12">
            <label for="start_date">Apertura del sorteo</label>
            <input
                id="start_date"
                v-model="value.start_date"
                type="datetime-local"
                class="form-control"
                placeholder="Ingresa una fecha"
                :class="{ 'is-invalid': v.raffle.start_date.$error && submitted }"
            />
            <div v-if="v.raffle.start_date.$error && submitted" class="invalid-feedback">Fecha inválida</div>
        </div>
        <div class="form-group col-lg-6 col-md-12">
            <label for="end_date">Cierre del sorteo</label>
            <input
                id="end_date"
                v-model="value.end_date"
                type="datetime-local"
                class="form-control"
                placeholder="Ingresa una fecha"
                :class="{ 'is-invalid': v.raffle.end_date.$error && submitted }"
            />
            <div v-if="v.raffle.end_date.$error && submitted" class="invalid-feedback">Fecha inválida</div>
        </div>
        <div class="form-group col-lg-6 col-md-12">
            <label for="draw_date">Fecha del sorteo</label>
            <input
                id="draw_date"
                v-model="value.draw_date"
                type="datetime-local"
                class="form-control"
                placeholder="Ingresa una fecha"
                :class="{ 'is-invalid': v.raffle.draw_date.$error && submitted }"
            />
            <div v-if="v.raffle.draw_date.$error && submitted" class="invalid-feedback">Fecha inválida</div>
        </div>
        <div class="form-group col-12">
            <label>Colaborador</label>
            <SelectAutocompleteField
                :url="`/collaborators`"
                :multiple="false"
                :placeholder="'Busca un colaborador'"
                :label="'business_name'"
                v-model="value.collaborator"
                :class="{ 'is-invalid': v.raffle.collaborator.$error && submitted }"
            ></SelectAutocompleteField>
            <div v-if="!v.raffle.collaborator.required && submitted" class="invalid-feedback">Colaborador es requerido</div>
        </div>
        <div class="form-group col-12">
            <label for="status">Estado</label>
            <select v-model="value.status" class="form-control"
                :class="{ 'is-invalid': v.raffle.status.$error && submitted }">
                <option v-for="option in raffle_status" v-bind:value="option.id" :key="option.id">
                    {{ option.label }}
                </option>
            </select>
        </div>
        <div class="form-group col-12">
            <label for="visibility">Visibilidad</label>
            <select v-model="value.visibility" class="form-control"
                :class="{ 'is-invalid': v.raffle.visibility.$error && submitted }">
                <option v-for="option in raffle_visibility" v-bind:value="option.id" :key="option.id">
                    {{ option.label }}
                </option>
            </select>
        </div>
        <div class="col-12">
            <label>Imagen destacada</label>
            <AddIndividualMediaButton v-model="value.feature_media" :onlyImages="true" :uploadModalId="'add-individual-media-modal-feature-media'"></AddIndividualMediaButton>
            <div v-if="!v.raffle.feature_media.required && submitted" class="invalid-feedback d-block">Imagen destacada es requerida</div>
        </div>                          
    </div> 
</template>