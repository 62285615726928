<script>
import { required, url, minValue, maxValue } from "vuelidate/lib/validators";
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';

import BasicDataPartial from "./partials/basic-data-partial.vue";
import DescriptionPartial from './partials/description-partial.vue';
import TermAndConditionPartial from "./partials/term-and-condition-partial.vue";
import FormFieldsPartial from "./partials/form-fields-partial.vue";
import RRSSPartial from "./partials/rrss-partial.vue";
import EmailDescriptionPartial from './partials/email-description-partial.vue';

const initialState = () => {
    return {
        submitted: false,
        raffle: {
            id: null,
            header_media_id: null,
            header_media: null,
            feature_media_id: null,
            feature_media: null,
            term_and_conditions_media_id: null,
            term_and_conditions_media: null,
            collaborator: null,
            collaborator_id: null,
            creator_id: null,
            title: null,
            slug: null,
            short_description: null,
            full_description: null,
            start_date: null,
            end_date: null,
            draw_date: null,
            status: 'draft',
            type: 'internal',
            detail_hide_title: false,
            facebook_url: null,
            instagram_url: null,
            tiktok_url: null,
            number_of_winners: 1,
            number_of_substitutes: 0,
            visibility: 'all',
            prize_value: null,
            email_description: null,
            raffle_form_fields:[],
        },
        activeTab:null
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number,
        collaboratorId: Number,
        initialActiveTab: Number,
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        BasicDataPartial,
        DescriptionPartial,
        TermAndConditionPartial,
        FormFieldsPartial,
        RRSSPartial,
        EmailDescriptionPartial
    },
    computed: {
    },
    validations: {
        raffle: {
            header_media_id: { },
            header_media: { required },
            feature_media_id: {  },
            feature_media: { required },
            term_and_conditions_media_id: {  },
            term_and_conditions_media: {  },
            collaborator: { },
            type: { },
            title: { required },
            short_description: { required },
            full_description: { required },
            start_date: { required },
            end_date: { required },
            draw_date: {  },
            facebook_url: { url },
            instagram_url: { url },
            tiktok_url: { url },
            number_of_winners: { required, minValue: minValue(1), maxValue: maxValue(100) },
            number_of_substitutes: { required, minValue: minValue(0), maxValue: maxValue(100) },
            visibility: {},
            prize_value: {},
            email_description: {},
            status: { },
            slug: { },            
            raffle_form_fields: {
                $each: {
                    label: { required },
                    type: { required },
                }
            },
        }
    },
    methods: {
        async shown() {

            if (this.id) {
                if(this.initialActiveTab){
                    this.activeTab = this.initialActiveTab;
                }
                const response = await api.get({
                    url: `raffles/${this.id}`,
                    config: {
                        withLoading: true
                    }  
                });

                const raffle = response?.data?.data;
                this.raffle = raffle;
            }
        },
        async confirm() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            const data = { ...this.raffle };
            data.feature_media_id = this.raffle?.feature_media?.id;
            data.header_media_id = this.raffle?.header_media?.id;
            data.term_and_conditions_media_id = this.raffle?.term_and_conditions_media?.id;
            data.collaborator_id = this.raffle?.collaborator?.id || this?.collaboratorId;

            const response = await api.save({
                url: 'raffles',
                id: this.raffle?.id,
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess", response?.data?.data);
        },
        hasErrorsInTab(tab) {
            
            if(tab === 'description_tab'){
                const fields = ['short_description', 'full_description'];
                return fields.some(field => this.$v.raffle[field].$invalid);
            }
            
            if(tab === 'raffle_form_fields_tab'){
                const fields = ['raffle_form_fields'];
                return fields.some(field => this.$v.raffle[field].$invalid);
            }
            
            if(tab === 'term_and_conditions_tab'){
                const fields = ['term_and_conditions_media'];
                return fields.some(field => this.$v.raffle[field].$invalid);
            }

            return false;
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
    }
};
</script>

<template>
    <Modal :title="'Formulario de sorteo'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'extra-xl'">
        <div class="row">

            <div class="col-lg-4 col-md-12 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-title">Datos básicos</h4>
                        <BasicDataPartial v-model="raffle" :v="$v" :submitted="submitted"></BasicDataPartial>                        
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-6 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <b-tabs v-model="activeTab" content-class="p-3 text-muted">
                            <b-tab class="border-0">
                                <template v-slot:title>
                                    <span :class="{ 'text-danger': submitted && hasErrorsInTab('description_tab') }">
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">
                                            <i class="ri-alert-line" v-if="submitted && hasErrorsInTab('description_tab')"></i>
                                            Descripción
                                        </span>
                                    </span>                                    
                                </template>
                                <b-card-text>
                                    <DescriptionPartial v-model="raffle" :v="$v" :submitted="submitted"></DescriptionPartial>  
                                </b-card-text>
                            </b-tab>                             
                            <b-tab class="border-0" v-if="raffle?.type === 'rrss'">
                                <template v-slot:title>
                                    <span :class="{ 'text-danger': submitted && hasErrorsInTab('rrss_tab') }">
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">
                                            <i class="ri-alert-line" v-if="submitted && hasErrorsInTab('rrss_tab')"></i>
                                            Redes sociales
                                        </span>
                                    </span>                                    
                                </template>
                                <b-card-text>
                                    <RRSSPartial v-model="raffle" :v="$v" :submitted="submitted"></RRSSPartial>  
                                </b-card-text>
                            </b-tab>                             
                            <b-tab class="border-0" v-if="raffle?.type === 'internal'">
                                <template v-slot:title>
                                    <span :class="{ 'text-danger': submitted && hasErrorsInTab('raffle_form_fields_tab') }">
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">
                                            <i class="ri-alert-line" v-if="submitted && hasErrorsInTab('raffle_form_fields_tab')"></i>
                                            Formulario
                                        </span>
                                    </span>                                   
                                </template>
                                <b-card-text>
                                    <FormFieldsPartial v-model="raffle.raffle_form_fields" :v="$v" :submitted="submitted"></FormFieldsPartial>  
                                </b-card-text>
                            </b-tab>                               
                            <b-tab class="border-0">
                                <template v-slot:title>
                                    <span :class="{ 'text-danger': submitted && hasErrorsInTab('term_and_conditions_tab') }">
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">
                                            <i class="ri-alert-line" v-if="submitted && hasErrorsInTab('term_and_conditions_tab')"></i>
                                            Términos y condiciones
                                        </span>
                                    </span>                                
                                </template>
                                <b-card-text>
                                    <TermAndConditionPartial v-model="raffle" :v="$v" :submitted="submitted"></TermAndConditionPartial>  
                                </b-card-text>
                            </b-tab>   
                            <b-tab class="border-0">
                                <template v-slot:title>
                                    <span :class="{ 'text-danger': submitted && hasErrorsInTab('email_description_tab') }">
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">
                                            <i class="ri-alert-line" v-if="submitted && hasErrorsInTab('email_description_tab')"></i>
                                            Mensaje de correo
                                        </span>
                                    </span>                                    
                                </template>
                                <b-card-text>
                                    <EmailDescriptionPartial v-model="raffle" :v="$v" :submitted="submitted"></EmailDescriptionPartial>  
                                </b-card-text>
                            </b-tab>                           
                        </b-tabs> 
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>