<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import RaffleForm from "./forms/raffle-form.vue";
import CustomTable from "@/components/tables/custom-table.vue";
import AddButton from "@/components/buttons/add-button.vue";
import Status from "@/helpers/general/status";
import RaffleShow from "./show/raffle-show.vue";
import CollaboratorShow from "@/views/pages/collaborators/show/show";
import DateRangeField from '@/components/forms/date-range-field.vue';

/**
 * Raffles Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        CustomTable,
        RaffleForm,
        AddButton,
        RaffleShow,
        CollaboratorShow,
        DateRangeField,
    },
    data() {
        return {
            showModalId: 'raffle-show-modal',
            showDiscountCodeModalId: 'raffle-show-modal',
            showCollaboratorModalId: 'raffle-collaborator-show-modal',
            formModalId: 'raffle-form-modal',
            pageHeader: {
                title: "Sorteos",
                breadcrumbs: [
                    {
                        text: "Sorteos",
                        href: "/raffles",
                        active: true
                    }
                ],
            },
            table: {
                url: '/raffles',
                params: {
                    'with_trashed': 1,
                },
                fields: [
                    { key: "id", label: "Cod", sortable: true },
                    { key: "title", label: "Titulo", sortable: true },
                    { key: "collaborator.business_name", label: "Colaborador", sortable: false },
                    { key: "creator.name", label: "Creado por", sortable: false },
                    { key: "start_date", label: "Desde", sortable: true },
                    { key: "end_date", label: "Hasta", sortable: true },
                    { key: "draw_date", label: "Fecha de sorteo", sortable: true },
                    { key: "type", label: "Tipo", sortable: false },
                    { key: "visibility", label: "Visibilidad", sortable: false },
                    { key: "status", label: "Estado", sortable: false },
                ],
                formatted_fields: [
                    'start_date',
                    'end_date',
                    'draw_date',
                    'title',
                    'status',
                    'type',
                    'visibility',
                    'collaborator.business_name',
                ]
            },
            raffle_status: Status.getList('raffle_status'),
            raffle_types: Status.getList('raffle_types'),
            raffle_visibility: Status.getList('raffle_visibility'),
            filters: {
                draw_date: {
                    startDate: null,
                    endDate: null
                },
                created_at_range: {
                    startDate: null,
                    endDate: null
                },
                status: 'all',
                type: "",
                visibility: 'all',
                collaborator_id: null
            },
            formSelectedItemId: null,
            formSelectedCollaboratorId: null,
            showSelectedItemId: null,
            showSelectedCollaboratorItem: null,
        };
    },
    methods: {
        showViewModal(item) {
            this.showSelectedItemId = item?.id || null;
            this.$bvModal.show(this.showModalId);
        },
        showFormModal(item) {
            this.formSelectedItemId = item?.id || null;
            this.formSelectedCollaboratorId = item?.collaborator_id || null;
            this.$bvModal.show(this.formModalId);
        },
        runRaffleModal(item) {
            this.showViewModal(item, 1);
        },
        showViewCollaboratorModal(item) {
            this.showSelectedCollaboratorItem = item?.collaborator_id || null;
            this.$bvModal.show(this.showCollaboratorModalId);
        },
        onSuccess() {
            this.$refs.raffleTable.refresh();
        },
        formatter(field, item) {
            switch (field) {
                case 'status':
                    return Status.getStatus({ itemStatus: item?.status, type: 'raffle_status', html: true });
                case 'type':
                    return Status.getStatus({ itemStatus: item?.type, type: 'raffle_types', html: true });
                case 'visibility':
                    return Status.getStatus({ itemStatus: item?.visibility, type: 'raffle_visibility', html: true });
                default:
                    return '';
            }
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <CollaboratorShow :modal-id="showCollaboratorModalId" :id="showSelectedCollaboratorItem"></CollaboratorShow>
                        <RaffleForm :modal-id="formModalId" :id="formSelectedItemId" :collaboratorId="formSelectedCollaboratorId" @onSuccess="onSuccess"></RaffleForm>
                        <RaffleShow :modal-id="showModalId" :id="showSelectedItemId"></RaffleShow>
                        <CustomTable
                            :url="table.url"
                            :fields="table.fields"
                            :params="table.params"
                            ref="raffleTable"
                            :formatted-fields="table.formatted_fields"
                            :formatter="formatter"
                            :show-view="true"
                            :show-edit="true"
                            :showSoftDelete="false"
                            @show="showViewModal"
                            @edit="showFormModal"
                            :show-modal-id="showModalId"
                            :filters2="filters"
                        >

                            <template v-slot:filters="{ filters }">
                                <div class="row">
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label>Fecha de creación</label>
                                        <DateRangeField v-model="filters.created_at_range" />
                                    </div>                                    
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label>Fecha de ejecución</label>
                                        <DateRangeField v-model="filters.draw_date" />
                                    </div>  
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="type">Tipo</label>
                                        <select v-model="filters.type" class="form-control">
                                            <option value="">Todos</option>
                                            <option v-for="option in raffle_types" v-bind:value="option.id" :key="option.id">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div> 
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="visibility">Visibilidad</label>
                                        <select v-model="filters.visibility" class="form-control">
                                            <option v-for="option in raffle_visibility" v-bind:value="option.id" :key="option.id">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>                                   
                                    <div class="form-group col-12 col-lg-3 col-sm-6">
                                        <label for="status">Estado</label>
                                        <select v-model="filters.status" class="form-control">
                                            <option value="all">Todos</option>
                                            <option v-for="option in raffle_status" v-bind:value="option.id" :key="option.id">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>                                   
                                </div>
                            </template>

                            <template v-slot:header-actions>
                                <AddButton @click="showFormModal"
                                    v-has-permission="{ permission: 'raffles.update', action: 'disable' }"></AddButton>
                            </template>
                            <template #cell-start_date="{ item }">
                                <span v-format-date="item?.start_date"></span>
                            </template>
                            <template #cell-end_date="{ item }">
                                <span v-format-date="item?.end_date"></span>
                            </template>
                            <template #cell-draw_date="{ item }">
                                <span v-format-date="item?.draw_date"></span>
                            </template>
                            <template #cell-title="{ item }">
                                <div :title="item?.title" v-b-tooltip.hover>{{ item?.title | truncateText(60) }}</div>
                            </template>
                            <template #cell-collaborator.business_name="{ item }">
                                <a href="javascript:void(0);" class="mr-1 text-primary" v-b-tooltip.hover title="Ver colaborador"
                                    v-if="item?.collaborator_id" @click="showViewCollaboratorModal(item)">
                                    <i class="ri-eye-fill font-size-18"></i>
                                </a>
                                <span>{{ item?.collaborator?.business_name }}</span>
                            </template>
                        </CustomTable>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>