<script>
import Vue from "vue";
import api from '@/helpers/apirest/api';
import CustomerShow from "@/views/pages/customers/show/show.vue";
import AddParticipantsForm from "@/views/pages/raffles/forms/add-participants/add-participant-form.vue";

export default {
    emits: ['onSuccess'],
    props: {
      raffle: {
            type: Object,
            default: () => {}
        },
    },
    components: {
        CustomerShow,
        AddParticipantsForm
    },
    data() {
        return {
            showModalId:'customer-show-modal-raffle',
            participantsFormModalId:'add-participants-form-modal',
            participantsFormModalType:'winners',
            showSelectedItemId:null,
        };
    },
    mounted(){
    },
    computed:{        
    },
    methods: {
        async run() {
            const result = await Vue.swal({
                title: "Ejecutar sorteo",
                icon: "info",
                html:  `Está seguro que desea ejecutar el sorteo?<br>La accion no se puede deshacer!`,
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Sí',
                cancelButtonText: 'Cancelar',
                });

                if (result.value) {
                    await api.post({
                        url: `raffles/${this.raffle?.id}/run`
                    });

                    this.onSuccess();
                }        
        },
        async notify() {
            const result = await Vue.swal({
                title: "Notificar ganadores y suplentes",
                icon: "info",
                html:  `Está seguro que desea enviar las notificaciones?<br>La accion no se puede deshacer!`,
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Sí',
                cancelButtonText: 'Cancelar',
                });

                if (result.value) {
                    await api.post({
                        url: `raffles/${this.raffle?.id}/notify`
                    });

                    this.onSuccess();
                }        
        },
        async deleteWinner(item) {
            const result = await Vue.swal({
                title: `Eliminar Ganador`,
                icon: "info",
                html:  `Está seguro que desea eliminar el ganador?`,
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Sí',
                cancelButtonText: 'Cancelar',
                });

                if (result.value) {
                    await api.delete({
                        url: `raffles/${this.raffle?.id}/winners`,
                        id: item?.id
                    });

                    this.onSuccess();
                }        
        },
        async deleteSubstitute(item) {
            const result = await Vue.swal({
                title: `Eliminar suplente`,
                icon: "info",
                html:  `Está seguro que desea eliminar el suplente?`,
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Sí',
                cancelButtonText: 'Cancelar',
                });

                if (result.value) {
                    await api.delete({
                        url: `raffles/${this.raffle?.id}/substitutes`,
                        id: item?.id
                    });

                    this.onSuccess();
                }        
        },
        onSuccess(){
            this.$emit('onSuccess');
        },
        showViewModal(item) {
            this.showSelectedItemId = item?.participant?.id || null;
            this.$bvModal.show(this.showModalId);
        },
        showAddParticipantModal(type) {
            this.participantsFormModalType = type;
            this.$bvModal.show(this.participantsFormModalId);
        },
    }
};
</script>

<template>
    <div class="row">
        <CustomerShow :modal-id="showModalId" :id="showSelectedItemId"></CustomerShow>
        <AddParticipantsForm :modal-id="participantsFormModalId" :raffle="raffle" :type="participantsFormModalType" @onSuccess="onSuccess"></AddParticipantsForm>
        <div class="col-12 mb-2" v-if="raffle?.type == 'internal'">
            <button type="button"
                class="btn btn-primary btn-block waves-effect waves-light"
                @click="run()"
                :disabled="raffle?.status != 'publish'"
                :title="raffle?.status != 'publish' ? 'El sorteo debe estar en estado publicado, para poder ser ejecutado' : 'Ejecutar sorteo'"
            >
                <i class="mdi mdi-play"></i> Ejecutar Sorteo
            </button>
        </div>
        <div class="col-12 mb-2" v-if="raffle?.type == 'internal'">
            <button type="button"
                class="btn btn-info btn-block waves-effect waves-light"
                @click="notify()"
                :disabled="raffle?.status != 'completed'"
                :title="raffle?.status != 'completed' ? 'Aún no se ha ejecutado el sorteo para poder notificar a los ganadores y suplentes' : 'Notificar ganadores y suplentes'"
            >
                <i class="mdi mdi-send"></i> Notificar Ganadores y Suplentes
            </button>
        </div>
        <div class="col-6">
            <div class="card h-100">
                <div class="card-body">                            
                    <h4 class="card-title mb-4">Ganadores</h4>
                    <div class="d-flex justify-content-center mb-4">
                        <div class="avatar-sm">
                            <span class="avatar-title text-center text-uppercase rounded-circle bg-soft-success text-success font-size-24">
                                {{ raffle?.number_of_winners }}
                            </span>
                        </div> 
                    </div>
                    <table class="table table-bordered table-sticky-header">
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">DNI</th>
                                <th scope="col">Notificado</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, i) in raffle?.raffle_winners" :key="i" class="">
                                <td>
                                    <a
                                        href="javascript:void(0);"
                                        class="mr-1 text-primary"
                                        v-b-tooltip.hover
                                        title="Ver perfil"
                                        @click="showViewModal(item)"
                                    >
                                        <i class="ri-eye-fill font-size-18"></i>
                                    </a>
                                    <span>{{ item?.participant?.name }}</span>
                                </td>
                                <td>
                                    {{ item?.participant?.identification }}
                                </td>
                                <td class="text-center">
                                    <span v-if="item?.notified" class="badge badge-soft-success font-size-11">Si</span>
                                    <span v-else class="badge badge-soft-warning font-size-11">No</span>
                                </td>
                                <td class="text-center">
                                    <a
                                        href="javascript:void(0);"
                                        class="text-danger"
                                        v-b-tooltip.hover
                                        title="Eliminar"
                                        @click="deleteWinner(item)"
                                    >
                                        <i class="mdi mdi-trash-can font-size-18"></i>
                                    </a>
                                </td>
                            </tr>                            
                        </tbody>
                        <tfoot v-if="raffle?.type == 'rrss'">
                            <tr>
                                <td colspan="4" class="text-right">
                                    <a
                                        href="javascript:void(0);"
                                        class="text-success"
                                        @click="showAddParticipantModal('winners')"
                                    >
                                        <i class="mdi mdi-plus-circle font-size-18"></i> Agregar ganadores
                                    </a>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card h-100">
                <div class="card-body">                            
                    <h4 class="card-title mb-4">Suplentes</h4>
                    <div class="d-flex justify-content-center mb-4">
                        <div class="avatar-sm">
                            <span class="avatar-title text-center text-uppercase rounded-circle bg-soft-info text-info font-size-24">
                                {{ raffle?.number_of_substitutes }}
                            </span>
                        </div> 
                    </div>
                    <table class="table table-bordered table-sticky-header">
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">DNI</th>
                                <th scope="col">Notificado</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, i) in raffle?.raffle_substitutes" :key="i" class="">
                                <td>
                                    <a
                                        href="javascript:void(0);"
                                        class="mr-1 text-primary"
                                        v-b-tooltip.hover
                                        title="Ver perfil"
                                        @click="showViewModal(item)"
                                    >
                                        <i class="ri-eye-fill font-size-18"></i>
                                    </a>
                                    <span>{{ item?.participant?.name }}</span>
                                </td>
                                <td>
                                    {{ item?.participant?.identification }}
                                </td>
                                <td class="text-center">
                                    <span v-if="item?.notified" class="badge badge-soft-success font-size-11">Si</span>
                                    <span v-else class="badge badge-soft-warning font-size-11">No</span>
                                </td>
                                <td class="text-center">
                                    <a
                                        href="javascript:void(0);"
                                        class="text-danger"
                                        v-b-tooltip.hover
                                        title="Eliminar"
                                        @click="deleteSubstitute(item)"
                                    >
                                        <i class="mdi mdi-trash-can font-size-18"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot v-if="raffle?.type == 'rrss'">
                            <tr>
                                <td colspan="4" class="text-right">
                                    <a
                                        href="javascript:void(0);"
                                        class="text-success"
                                        @click="showAddParticipantModal('substitutes')"
                                    >
                                        <i class="mdi mdi-plus-circle font-size-18"></i> Agregar suplentes
                                    </a>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
  </div>
</template>