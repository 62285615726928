<script>
import CustomTable from "@/components/tables/custom-table.vue";
import CustomerShow from "@/views/pages/customers/show/show.vue";
import api from '@/helpers/apirest/api';

/**
 * Collaborators Component
 */
export default {
    props: {
        raffle: Object
    },
    emits: ['onSuccess'],
    components: {
        CustomTable,
        CustomerShow
    },
    data() {
        return {
            raffleParticipantShowModalId: 'raffle-participant-show-modal',
            table: {
                params: {
                    'with_trashed': 1,
                },
            },
            raffleParticipanSelectedId: null,
        };
    },
    computed: {
        tableUrl() {
            return `/raffles/${this.raffle?.id}/participants`;
        },
        fields() {
            let fields = [
                { key: "id", label: "Cod", sortable: true },
                { key: "created_at", label: "Fecha de participación", sortable: true },
                { key: "participant.name", label: "Nombre", sortable: true },
            ];

            this.raffle?.raffle_form_fields?.forEach(field => {
                fields.push({
                    key: `fields.${field.id}`,
                    label: field.label,
                    sortable: false,
                });
            })

            return fields;

        },
        formattedFields() {
            let fields = [
                'created_at',
                'participant.name',
            ];
            this.raffle?.raffle_form_fields?.forEach(field => {
                fields.push( `fields.${field.id}`);
            });

            return fields;
        },
    },
    methods: {
        showViewModal(item) {
            this.raffleParticipanSelectedId = item?.participant?.id || null;
            this.$bvModal.show(this.raffleParticipantShowModalId);
        },
        formatter(field, item) {
            switch (field) {              
                default:
                {
                    const formField = this.raffle?.raffle_form_fields?.find(formField => `fields.${formField.id}` === field);
                    if (formField) {
                        const answer = item?.answers?.find(answer => answer.raffle_form_field_id === formField.id);
                        if(!answer) return '';
                        switch (formField.type) {
                            case 'file':
                                return `<a href="${answer?.answer}" target="_blank">${answer?.answer}</a>`;
                            default:
                                return answer?.answer;
                        }
                    }
                }
                return '';
            }
        },
        async download() {
            const response = await api.get({
                url: `raffles/${this.raffle?.id}/participants/download`,
                config: {
                    withLoading: true
                }  
            });

            const fileData = response?.data?.data;

            let a = document.createElement('a');
            a.href = fileData.url;
            a.download = 'raffle_participants.xlsx';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
    }
};
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <CustomerShow :modal-id="raffleParticipantShowModalId" :id="raffleParticipanSelectedId"></CustomerShow>
                    <button class="btn btn-primary" @click="download()">Descargar participantes</button>
                    <CustomTable
                        v-if="raffle"
                        :url="tableUrl"
                        :fields="fields"
                        :formatted-fields="formattedFields"
                        :formatter="formatter"
                        :params="table.params"
                        ref="raffleParticipantsTable"
                        :show-view="false"
                        :show-edit="false"
                        :showSoftDelete="false"
                    >
                        <template #cell-created_at="{ item }">
                            <span v-format-date="item?.created_at"></span>
                        </template>
                        <template #cell-participant.name="{ item }">
                            <a href="javascript:void(0);" class="mr-1 text-primary" v-b-tooltip.hover title="Ver titular"
                                v-if="item?.participant?.id" @click="showViewModal(item)">
                                <i class="ri-eye-fill font-size-18"></i>
                            </a>
                            <span>{{ item?.participant?.name }}</span>
                        </template>
                    </CustomTable>
                </div>
            </div>
        </div>
    </div>
</template>