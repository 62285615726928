<script>
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
};
</script>

<template>
    <div class="row">
        <div class="form-group col-12">
            <label for="facebook_url">Facebook</label>
            <input id="facebook_url"
                v-model="value.facebook_url"
                type="text"
                class="form-control"
                placeholder="Ingresa una URL para Facebook"
                :class="{ 'is-invalid': v.raffle.facebook_url.$error && submitted }"
            />
            <div v-if="v.raffle.facebook_url.$error && submitted" class="invalid-feedback">Url inválida</div>
        </div>
        <div class="form-group col-12">
            <label for="instagram_url">Instagram</label>
            <input id="instagram_url"
                v-model="value.instagram_url"
                type="text"
                class="form-control"
                placeholder="Ingresa una URL para Instagram"
                :class="{ 'is-invalid': v.raffle.instagram_url.$error && submitted }"
            />
            <div v-if="v.raffle.instagram_url.$error && submitted" class="invalid-feedback">Url inválida</div>
        </div>
        <div class="form-group col-12">
            <label for="tiktok_url">Tiktok</label>
            <input id="tiktok_url"
                v-model="value.tiktok_url"
                type="text"
                class="form-control"
                placeholder="Ingresa una URL para Tiktok"
                :class="{ 'is-invalid': v.raffle.tiktok_url.$error && submitted }"
            />
            <div v-if="v.raffle.tiktok_url.$error && submitted" class="invalid-feedback">Url inválida</div>
        </div>
    </div> 
</template>