<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editor: ClassicEditor,
        }
    },
    components: {        
        ckeditor: CKEditor.component,
    },
    methods: {
        
    },
};
</script>

<template>
    <div class="row">
        <div class="form-group col-12">
            <label>Descripción destacada (Máx 300 caracteres)</label>
            <p class="text-muted font-13">Ingresa un texto llamativo para destacar el sorteo.</p>
            <textarea
                v-model="value.short_description"
                class="form-control"
                :maxlength="300"
                rows="3"
                :class="{ 'is-invalid': v.raffle.short_description.$error && submitted }"
            ></textarea>
        </div>
        <div class="form-group col-12">
            <label for="full_description">Descripción completa</label>
            <p class="text-muted font-13">Utiliza este campo para describir de forma detallada el sorteo.</p>
            <ckeditor id="full_description" v-model="value.full_description" :editor="editor"></ckeditor>
            <div v-if="!v.raffle.full_description.required && submitted" class="invalid-feedback d-block">Ingresa la descripción detallada del sorteo</div>
        </div>
    </div> 
</template>