<script>
import AddIndividualMediaButton from "@/components/buttons/add-individual-media-button.vue";
export default {
    props: {
        value: Object,
        v: Object,
        submitted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    components: {        
        AddIndividualMediaButton
    },
    methods: {
        
    },
};
</script>

<template>
    <div class="row">
        <div class="col-12">
            <label>Terminos y condiciones</label>
            <AddIndividualMediaButton
                v-model="value.term_and_conditions_media"
                :fullWidth="true"
                :uploadModalId="'add-individual-media-modal-terms-and-conditions-media'"
                extension="pdf"
            ></AddIndividualMediaButton>
            <div v-if="v.raffle.term_and_conditions_media.$error && submitted" class="invalid-feedback d-block">Archivo inválido</div>
        </div>
    </div> 
</template>