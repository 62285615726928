<script>
import api from '@/helpers/apirest/api';
import Modal from "@/components/modals/modal-form.vue";
import RaffleForm from "../forms/raffle-form.vue";
import Status from "@/helpers/general/status";
import RunRafflePartial from "./partials/run-raffle-partial.vue";
import ParticipantsPartial from "./partials/participants-partial.vue";

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return {     
            raffle:null,
            showOrderModalId:'order-show-modal',
            formModalId:'raffle-form-detail-modal',
            raffleIntegrationStatus: Status,
            showSelectedItemId:null,
            activeTab:0
        }
    },
    components: {
        Modal,
        RaffleForm,
        RunRafflePartial,
        ParticipantsPartial
    },
    computed: {
        modalTilte(){
            return `Detalle del sorteo #${(this?.id || '')}`;
        },   
        status(){
            return Status.getStatus({itemStatus: this?.raffle?.status, type: 'raffle_status', html: true});
        }, 
        type(){
            return Status.getStatus({itemStatus: this?.raffle?.type, type: 'raffle_types', html: true});
        },  
        visibility(){
            return Status.getStatus({itemStatus: this?.raffle?.visibility, type: 'raffle_visibility', html: true});
        },  
    },
    methods: {    
        async getData(){
            if (this.id) {
                const response = await api.get({
                    url: `raffles/${this.id}`,
                    config: {
                        withLoading: true
                    }
                });
                this.raffle = response?.data?.data;
            }
        },
        shown() {
            this.getData();
        },
        hidden() {
            this.raffle = null;
        },
        showFormModal() {
            this.$bvModal.show(this.formModalId);
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        onSuccess(){    
            this.getData();        
        },
        formatter() {
        },
    }
};
</script>

<template>
    <Modal :title="modalTilte" :id="modalId" @cancel="cancel" @shown="shown" :size="'extra-xl'" :showConfirmButton="false" :label-cancel-btn="'Cerrar'">
        <RaffleForm :modal-id="formModalId" :id="id" @onSuccess="onSuccess"></RaffleForm>
        <div class="row mb-4">
            <div class="col-xl-3 col-lg-4">
                <div class="card h-100">
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col-12 d-flex align-items-center flex-column justify-content-center">
                                <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                    <template #loading>
                                        <b-skeleton type="avatar"></b-skeleton>
                                        <b-skeleton width="100%"></b-skeleton>
                                        <b-skeleton width="100%"></b-skeleton>                                            
                                    </template> 

                                    <b-dropdown no-flip right class="qlk-dropdown-sm" style="position: absolute; top: 0px; right: 10px" variant="light" size="sm">
                                        <template v-slot:button-content>
                                            <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
                                        </template>
                                        <b-dropdown-item @click="showFormModal">Editar</b-dropdown-item>
                                    </b-dropdown>

                                    <div class="avatar-sm">
                                        <span class="avatar-title text-center text-uppercase rounded-circle bg-soft-primary text-primary">
                                        {{ raffle?.title?.charAt(0) + raffle?.title?.charAt(1) }}
                                        </span>
                                    </div> 
                                    <h4 class="mt-3 mb-0">{{ raffle?.title }}</h4>
                                    <p class="text-muted font-size-14 mb-0 text-center">
                                        {{ raffle?.collaborator?.business_name }}<br>
                                        <span v-html="type" title="Tipo de sorteo"></span>
                                    </p>
                                </b-skeleton-wrapper>
                            </div>
                        </div> 
                        <!-- <hr class="my-3" />  -->
                        <div class="row">
                            <div class="col-12">
                                <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                    <template #loading>
                                        <b-skeleton-table
                                            :rows="4"
                                            :columns="2"
                                            :hide-header="true"
                                            :table-props="{ bordered: true, striped: true }"
                                        ></b-skeleton-table> 
                                    </template>
                                    <table class="table table-hover mb-0 table-centered">
                                        <tbody>
                                            <tr>
                                                <th class="text-right nowrap">Estado</th>
                                                <td v-html="status"></td>
                                            </tr>
                                            <tr>
                                                <th class="text-right nowrap">Participantes suscritos</th>
                                                <td>{{ raffle?.participants_count }}</td>
                                            </tr>
                                            <tr>
                                                <th class="text-right nowrap">Apertura</th>
                                                <td>{{ raffle?.start_date }}</td>
                                            </tr>
                                            <tr>
                                                <th class="text-right nowrap">Cierre</th>
                                                <td>{{ raffle?.end_date }}</td>
                                            </tr>
                                            <tr>
                                                <th class="text-right nowrap">Fecha del sorteo</th>
                                                <td>
                                                    <span
                                                        v-if="raffle?.draw_date"
                                                        class="badge badge-soft-success font-size-11"
                                                    >{{ raffle?.draw_date }}</span>
                                                    <span
                                                        v-else
                                                        class="badge badge-soft-warning font-size-11"
                                                    > Pendiente de realizar</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="text-right nowrap">Premio individual</th>
                                                <td>{{ raffle?.prize_value | currency }}</td>
                                            </tr>
                                            <tr>
                                                <th class="text-right nowrap">Visibilidad</th>
                                                <td v-html="visibility"></td>
                                            </tr>
                                            <tr>
                                                <th class="text-right nowrap">Terminos y condiciones</th>
                                                <td>
                                                    <a :href="raffle?.term_and_conditions_media?.url" target="_blank">Ver</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </b-skeleton-wrapper> 
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            <div class="col-xl-9 col-lg-8">
                <div class="card h-100">
                    <div class="card-body">
                        <b-tabs v-model="activeTab" content-class="p-3 text-muted">
                            <b-tab class="border-0" lazy>
                                <template v-slot:title>
                                <span class="d-inline-block d-sm-none">
                                    <i class="fas fa-home"></i>
                                </span>
                                <span class="d-none d-sm-inline-block">Sorteo</span>
                                </template>
                                <b-card-text>
                                    <RunRafflePartial :raffle="raffle" @onSuccess="onSuccess"></RunRafflePartial>                                        
                                </b-card-text>
                            </b-tab>                         
                            <b-tab class="border-0" lazy>
                                <template v-slot:title>
                                <span class="d-inline-block d-sm-none">
                                    <i class="fas fa-home"></i>
                                </span>
                                <span class="d-none d-sm-inline-block">Participantes</span>
                                </template>
                                <b-card-text>
                                    <ParticipantsPartial :raffle="raffle" @onSuccess="onSuccess"></ParticipantsPartial>                                        
                                </b-card-text>
                            </b-tab>                         
                        </b-tabs>                      
                    </div>
                </div>
            </div>
        </div>
    </Modal> 
</template>